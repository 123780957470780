:root{
  --primary-err-color: #FF2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #F2F2F2;
  --disable-bg-color: #606060;
} 

html[data-theme="penta"]{
  --primary-theme-color: rgba(93,188,210);
  --primary-theme-color-half: rgba(93,188,210, 0.5);
  --primary-theme-color-08: rgba(93,188,210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93,188,210, 0.2);
  --light-bg-color: rgba(93,188,210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0,42,132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"]{
  --primary-theme-color: rgba(35,153,229);
  --primary-theme-color-half: rgba(35,153,229, 0.5);
  --primary-theme-color-08: rgba(35,153,229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35,153,229, 0.2);
  --light-bg-color: rgba(35,153,229, 0.3);
  --dark-bg-color: rgba(35,153,229);
  --dark-bg-color-07: rgba(35,153,229, 0.7);
  --chat-bubble-color: rgba(35,153,229, 0.2);
  --chat-bubble-color-selected: rgba(35,153,229, 0.4);
  --primary-err-color: #FF4D4D;
  --std-border: 0;
}

.ChatList .row{
  padding: 3%;
  padding-bottom: 10%;
  display: flex; 
  flex-direction: row;
}

.ChatList .row .detail{
  padding-left: 3%;
  width: 90%;
}

.ChatList .row .time{
  margin-left: auto;
}

.ChatList .app-doc-name{
  word-wrap: break-all;
}

.ChatList .row.selected,
.ChatList .row:hover{
  background-color: var(--hover-bg);
}

.ChatList{
  overflow-y: auto;
}

.ChatList .row .badge{
  background: var(--primary-err-color); 
  width: 13px; 
  height: 13px;
  border-radius: 13px;
  margin-left: -8px;
  z-index: 1;
}

.chatListError{
  height: 93vh;
  text-align: center;
}

.chatListError .dimmable{
  top: 40%;
}

.chatListError .button,
.chatListError .button:hover,
.chatListError .button:active,
.chatListError .button:focus{
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}
