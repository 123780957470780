:root{
  --primary-err-color: #FF2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #F2F2F2;
  --disable-bg-color: #606060;
} 

html[data-theme="penta"]{
  --primary-theme-color: rgba(93,188,210);
  --primary-theme-color-half: rgba(93,188,210, 0.5);
  --primary-theme-color-08: rgba(93,188,210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93,188,210, 0.2);
  --light-bg-color: rgba(93,188,210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0,42,132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"]{
  --primary-theme-color: rgba(35,153,229);
  --primary-theme-color-half: rgba(35,153,229, 0.5);
  --primary-theme-color-08: rgba(35,153,229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35,153,229, 0.2);
  --light-bg-color: rgba(35,153,229, 0.3);
  --dark-bg-color: rgba(35,153,229);
  --dark-bg-color-07: rgba(35,153,229, 0.7);
  --chat-bubble-color: rgba(35,153,229, 0.2);
  --chat-bubble-color-selected: rgba(35,153,229, 0.4);
  --primary-err-color: #FF4D4D;
  --std-border: 0;
}


.main-nurse button.ui.button.primary.outline{
  color: var(--primary-theme-color);
  background-color: var(--primary-font-color);
  border: 2px solid var(--primary-theme-color);
}

.TimeNurse{
  padding: 1%;
  padding-left: 0;
  cursor: default;
}

.TimeNurse .title{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.TimeNurse .sub-title{
  padding: 1%;
}

.TimeNurse .title .button{
  background-color: var(--primary-theme-color-half);
}

.TimeNurse .title .button:hover{
  background-color: var(--primary-theme-color-08);
}

.TimeNurse .noProvider{
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-top: 15px;
  color: var(--primary-theme-color);
}

.TimeNurse .column{
  flex: 1;
}

.TimeNurse .provider{
  display: flex;
  align-items: center;
  margin-left: 50px;
  /* margin-left: 100px;  */
  margin-top: 10px;
}

.TimeNurse .provider .provider-name{
  margin: 0;
  width: 50%;
  word-break: break-word;
}

.TimeNurse .currentDate{
  margin-left: 10px; 
  margin-right: 10px;
}

.TimeNurse .appointment{
  margin-left: 150px; 
  margin-top: 5px;
}

.TimeNurse .queue-filter{
  background-color:  var(--light-bg-color);
  width: 65%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.TimeNurse .queue-filter.ui.form .inline.fields .field{
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}