/* AppointmentDashboard.css */

:root {
  --primary-err-color: #ff2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #f2f2f2;
  --disable-bg-color: #606060;
}

html[data-theme="penta"] {
  --primary-theme-color: rgba(93, 188, 210);
  --primary-theme-color-half: rgba(93, 188, 210, 0.5);
  --primary-theme-color-08: rgba(93, 188, 210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93, 188, 210, 0.2);
  --light-bg-color: rgba(93, 188, 210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0, 42, 132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"] {
  --primary-theme-color: rgba(35, 153, 229);
  --primary-theme-color-hover: rgb(30, 124, 187);
  --primary-theme-color-half: rgba(35, 153, 229, 0.5);
  --primary-theme-color-08: rgba(35, 153, 229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35, 153, 229, 0.2);
  --light-hover-bg: rgba(35, 153, 229, 0.1);
  --light-bg-color: rgba(35, 153, 229, 0.3);
  --dark-bg-color: rgba(35, 153, 229);
  --dark-bg-color-07: rgba(35, 153, 229, 0.7);
  --chat-bubble-color: rgba(35, 153, 229, 0.2);
  --chat-bubble-color-selected: rgba(35, 153, 229, 0.4);
  --primary-err-color: #ff4d4d;
  --primary-correct-color: #27ae60;
  --std-border: 0;
  --dashboard-label-bg: rgba(234, 246, 252, 0.5);
}

.appointment-db-searchbar{
  margin: 10px;
}

.appointment-db-searchbar .hilight {
  color: var(--primary-theme-color);
}

.appointment-db-searchbar .filter-row{
  display: flex;
  flex-wrap: wrap;
}

.appointment-db-searchbar .filter-row>div{
  white-space: nowrap; 
  display: flex; 
  align-items: center; 
  flex-basis: 30%;
}

.appointment-db-searchbar .filter-row>div.date-range,
.appointment-db-searchbar .filter-row>div.birthdate{
  flex-basis: 40%;
}

.appointment-db-searchbar .filter-row .width100,
.appointment-db-searchbar .filter-row .width100.ui.dropdown,
.appointment-db-searchbar .filter-row .DayPickerInput{
  flex-grow: 1;
  margin: 10px;
}

.appointment-db-searchbar .filter-row .ui.input{
  width: 100%;
}

.appointment-db-searchbar .search-button-area {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.appointment-db-searchbar .search-button,
.appointment-db-searchbar .search-button:focus,
.appointment-db-searchbar .search-button:hover {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
} 


/* .appointment-db-searchbar {
  display: flex;
  margin: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: bold;
}

.appointment-db-searchbar .break {
  display: flex;
  flex-basis: 100%;
}

.appointment-db-searchbar .search-component {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  flex:26;
}

.appointment-db-searchbar .search-component-last {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  flex:48;
}

.appointment-db-searchbar .search-component .key,
.appointment-db-searchbar .search-component-last .key {
  flex:2;
  text-align: right;
  padding-right: 8px;
  min-width: fit-content;
}

.appointment-db-searchbar .search-component-last .key {
  min-width: fit-content;
}

.appointment-db-searchbar .search-component .value,
.appointment-db-searchbar .search-component .value .input {
  flex:10;
  width: 100%;
}

.appointment-db-searchbar .search-component-last .value {
  flex:10;
  padding-right: 8px;
  width: 100%;
}

.appointment-db-searchbar .DayPickerInput ,
.appointment-db-searchbar .DayPickerInput .input {
  width: 100%;
}

.appointment-db-searchbar .hilight {
  text-align : center;
  color: var(--primary-theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex: 5;
}

.appointment-db-searchbar .hilight label {
  padding-right: 8px;
  padding-left: 8px;
}

.appointment-db-searchbar .search-button-area {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.appointment-db-searchbar .search-button,
.appointment-db-searchbar .search-button:focus {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
} 

.appointment-db-searchbar .search-button:hover{
  background-color: var(--primary-theme-color-hover);
  color: var(--primary-font-color);
}  */

.appointment-dashboard {
  height: 100%;
  overflow: auto;
  flex-direction: column;
}

.appointment-dashboard .title {
  padding: 10px;
  margin : 10px;
  font-size: 30px;
  font-weight: bolder;
  color: var(--primary-theme-color);
}

.appointment-dashboard .main-table {
  flex: 1 1 auto;
  overflow: auto;
  margin: 10px;
}

.appointment-dashboard .footer {
  flex: 0 1 40px;
}

.appointment-dashboard .rt-td .full-name,
.appointment-dashboard .rt-td .request-adt,
.appointment-dashboard .rt-td .doctor-name,
.appointment-dashboard .rt-td .hn,
.appointment-dashboard .rt-td .location,
.appointment-dashboard .rt-td .dob { 
}

.appointment-dashboard .rt-td {
  align-self: center ;
}

.appointment-dashboard .rt-tr-group{
  background-color: rgba(45,156,219,0.1);
  min-height: 55px;
  font-weight: bold;
}
.appointment-dashboard .rt-tr-group:nth-of-type(even){
  background-color: rgba(45,156,219,0.2);
}