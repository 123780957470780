:root{
  --primary-err-color: #FF2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #F2F2F2;
  --disable-bg-color: #606060;
} 

html[data-theme="penta"]{
  --primary-theme-color: rgba(93,188,210);
  --primary-theme-color-half: rgba(93,188,210, 0.5);
  --primary-theme-color-08: rgba(93,188,210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93,188,210, 0.2);
  --light-bg-color: rgba(93,188,210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0,42,132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"]{
  --primary-theme-color: rgba(35,153,229);
  --primary-theme-color-half: rgba(35,153,229, 0.5);
  --primary-theme-color-08: rgba(35,153,229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35,153,229, 0.2);
  --light-bg-color: rgba(35,153,229, 0.3);
  --dark-bg-color: rgba(35,153,229);
  --dark-bg-color-07: rgba(35,153,229, 0.7);
  --chat-bubble-color: rgba(35,153,229, 0.2);
  --chat-bubble-color-selected: rgba(35,153,229, 0.4);
  --primary-err-color: #FF4D4D;
  --std-border: 0;
}


.MyMessage {
  margin: 1%;
}

.MyMessage .inline.fields .field.content {
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--chat-bubble-color);
  border-radius: 1.3em;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 60%;
}

.MyMessage .inline.fields .field.content.selected {
  background-color: var(--chat-bubble-color-selected);
}

.MyMessage .inline.fields .field.content .file {
  text-align: center;
  padding: 5px;
}

.MyMessage .inline.fields .field.content .file .text {
  padding-top: 10px;
}

.MyMessage .inline.fields .field.sendDateTime {
  font-size: 12;
  padding: 0.5em;
  text-align: initial;
  align-self: flex-end;
}

.MyMessage .fields {
  direction: rtl;
}

.MyMessage .inline.fields .field.content .call.icon{
  transform: rotate(90deg)
}

/* .MyMessage .inline.fields{
  justify-content: flex-end;
} */